import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapPin } from 'react-feather'

let mapkey = ''
if (process.env.NETLIFY_MAP_KEY) {
  mapkey = process.env.NETLIFY_MAP_KEY
}

class GoogleMap extends Component {
  // static defaultProps = {
  //   center: {
  //     lat: -28.0914483,
  //     lng: 153.4425208
  //   },
  //   zoom: 14
  // }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={-28.0914483} lng={153.4425208} text={'Kreyser Avrora'} />
        </GoogleMapReact> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d112282.75946118767!2d77.2751796191662!3d28.424197999999997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89fea5b7f247ceb7!2sSAR%20Industries!5e0!3m2!1sen!2sin!4v1615638387153!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{border:"0"}}
          allowFullScreen= {true}
          loading="lazy"
        ></iframe>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin />
    </div>
  )
}
